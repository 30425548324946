import { navigate } from 'gatsby'
import React, { useState } from 'react'
import * as rdd from 'react-device-detect'
import { v4 as uuidv4 } from 'uuid'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonsFloating from 'components/ButtonsFloating'
import ButtonWithModal from 'components/ButtonWithModal'
import Copy from 'components/Copy'
import Heading from 'components/Heading'
import Image from 'components/Image'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import paths from 'constants/paths'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import { ReactComponent as ArrowUpRightSvg } from 'features/enterprise/components/EnterpriseEligibilityForm/images/arrow---up-right.svg'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function OnboardingEnterpriseIneligiblePairtu() {
  useEventPageVisited('Special: Nay page')
  const globalContext = useGlobalContext()
  const [isLearnMore, setIsLearnMore] = useState(false)
  const [trackingId] = useState(uuidv4())

  function buildProgram() {
    navigate(paths.ONBOARDING_PROGRAM_BUILD)
  }

  function startProgram() {
    globalContext.analytics?.trackEvent('Special: Nay page: I want to try bold free click', {
      trackingId,
    })
    buildProgram()
  }

  function handleLearnMore() {
    globalContext.analytics?.trackEvent('Special: Nay page: Learn More Click', {
      trackingId,
    })
    setIsLearnMore(true)
  }

  function closeModal() {
    setIsLearnMore(false)
  }

  function handleOnClose() {
    globalContext.analytics?.trackEvent('Special: Nay page: Modal close', {
      trackingId,
    })
    closeModal()
  }

  function handleConfirmCancel() {
    globalContext.analytics?.trackEvent('Special: Nay page: Modal no Stay here', { trackingId })
    closeModal()
  }

  function handleRedirectToPairtu() {
    globalContext.analytics?.trackEvent('Special: Nay page: Modal continue', {
      trackingId,
    })
    closeModal()
    buildProgram()
  }

  const modalContent = (
    <>
      <Text element="p">
        You are leaving <Text weight="medium">agebold.com</Text> to visit Pairtu at the web address{' '}
        <Text weight="medium">pairtu.com</Text>. You can also call Pairtu at{' '}
        <Text weight="bold" color="purple">
          (213) 267-7359.
        </Text>
      </Text>
      <Text element="p" flush size="small">
        Please note that Pairtu is not owned, operated, or controlled by Age Bold, Inc. or its
        affiliates. Visiting Pairtu's website is completely voluntary, and you'll be subject to
        Pairtu's terms of use and privacy policies.
      </Text>
    </>
  )

  return (
    <>
      <Row>
        <Row size="smallx">
          <Text size="large" element="blockquote" color="purple" weight="bold">
            We couldn’t verify your eligibility, but you have options
          </Text>
          <Heading level={4}> Get expert healthcare guidance with Pairtu.</Heading>
        </Row>
        <Row>
          <Row size="small">
            <Image
              src={
                rdd.isMobile
                  ? '/images/onboarding/pairtu-mobile.jpg'
                  : '/images/onboarding/pairtu.jpg'
              }
              alt="Pairtu healthcare services illustration"
            />
          </Row>
          <ButtonsFloating>
            <Buttons axis="y" size="xsmall">
              <ButtonWithModal
                modal={
                  <>
                    {isLearnMore &&
                      (rdd.isMobile ? (
                        <ModalBox title="You are leaving Bold’s website">
                          <Row>
                            <Copy>{modalContent}</Copy>
                          </Row>
                          <Buttons axis="y" size="xsmall">
                            <Button
                              onClick={handleRedirectToPairtu}
                              to={urls.PAIRTU.concat('&uid=', trackingId)}>
                              <TextWithIcon icon={<ArrowUpRightSvg />} iconPosition="right" inline>
                                Yes, continue
                              </TextWithIcon>
                            </Button>
                            <Button color="white" onClick={handleConfirmCancel}>
                              No, stay here
                            </Button>
                          </Buttons>
                        </ModalBox>
                      ) : (
                        <ModalBox title="You are leaving Bold’s website.">
                          <Row>
                            <Copy>{modalContent}</Copy>
                          </Row>
                          <Buttons align="center" equal>
                            <Button color="white" onClick={handleConfirmCancel}>
                              No, stay here
                            </Button>
                            <Button
                              onClick={handleRedirectToPairtu}
                              to={urls.PAIRTU.concat('&uid=', trackingId)}>
                              <TextWithIcon icon={<ArrowUpRightSvg />} iconPosition="right" inline>
                                Yes, continue
                              </TextWithIcon>
                            </Button>
                          </Buttons>
                        </ModalBox>
                      ))}
                  </>
                }
                showModal={isLearnMore}
                onClick={handleLearnMore}
                onClose={handleOnClose}>
                Learn more about Pairtu
              </ButtonWithModal>
              <Button color="white" full size="medium" onClick={startProgram}>
                I want to try Bold for 14 days, free
              </Button>
            </Buttons>
          </ButtonsFloating>
        </Row>
        <Text color="grey" element="p" flush size="xsmall">
          Please Note: Pairtu is an independent healthcare provider that offers personalized
          healthcare coordination services. Pairtu can help you navigate medical care, connect you
          with healthcare professionals, and manage ongoing health concerns. Pairtu is not
          affiliated with Age Bold, Inc., Medicare, or any government agency. For comprehensive
          information on healthcare coverage options, please visit Medicare.gov, call 1-800-MEDICARE
          (TTY: 1-877-486-2048), or contact your local State Health Insurance Assistance Program
          (SHIP).
        </Text>
      </Row>
    </>
  )
}

import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Copy from 'components/Copy'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as assessments from 'libs/assessments'
import * as checkinApiRequest from 'libs/checkin-api-request'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import styles from './AssessmentStrengthLowerBodyModals.module.scss'

export default function AssessmentStrengthLowerBodyModals() {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [showAssessment, setShowAssessment] = React.useState(false)
  const [showConsent, setShowConsent] = React.useState(false)
  const checkinCode = React.useRef()

  React.useEffect(() => {
    async function getCheckin() {
      if (!globalContext.user || globalContext.isVideoPlaying) return

      const checkinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'assessment_sit_stand',
          activeOnly: true,
          createIfNotExists: false,
        })
        .catch((error: any) => {
          console.error(error)
        })

      if (checkinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(globalContext?.user?.id, checkinResponse.data, globalContext.analytics)
        )
        setShowConsent(true)
        checkinCode.current = checkinResponse.data.code
        globalContext.analytics?.trackEvent('Assessment Pop Up shown', {
          code: checkinCode.current,
        })
      }
    }
    getCheckin()
  }, [globalContext.analytics, globalContext.isVideoPlaying, globalContext.user])

  function markCheckinAsComplete() {
    checkinApiRequest.markUserCheckinAsComplete(
      checkinTracker.checkinId,
      checkinTracker._collectAllResponses()
    )
  }

  function closeModals() {
    setShowAssessment(false)
    setShowConsent(false)
  }

  function handleClose(modalName: string) {
    markCheckinAsComplete()
    globalContext.analytics?.trackEvent('Assessment modal closed', {
      modalName,
      code: checkinCode.current,
    })
    closeModals()
  }

  function handleConsentConfirm() {
    globalContext.analytics?.trackEvent('Assessment consent modal accepted', {
      code: checkinCode.current,
    })
    setShowConsent(false)
    setShowAssessment(true)
  }

  function goToAssessment() {
    markCheckinAsComplete()
    globalContext.analytics?.trackEvent('Assessment modal accepted', {
      code: checkinCode.current,
    })
    navigate(paths.ASSESSMENTS + assessments.ASSESSMENT_STRENGTH_LOWER_BODY_INTERNAL_ID)
  }

  const title = 'Lower body strength assessment'

  const commonProps = {
    hideClose: true,
    shouldCloseOnOverlayClick: false,
    className: 'AssessmentStrengthLowerBodyModals',
  }

  return (
    <>
      <Modal {...commonProps} isOpen={showConsent}>
        <ModalBox title={title}>
          <Row size="small">
            <Copy>
              <Text element="p">
                Do you think you would be able to complete this sit-to-stand assessment?{' '}
                <Text weight="bold">To complete this assessment, you must</Text>
              </Text>
              <Text element="ul">
                {[
                  'Be able to rise from a chair to a full standing position without using your arms.',
                  'Have a chair that is sturdy and wonʼt slip.',
                ].map((item) => (
                  <Text element="li" key={item}>
                    {item}
                  </Text>
                ))}
              </Text>
            </Copy>
          </Row>
          <Buttons axis="y" size="small">
            <Button onClick={handleConsentConfirm}>Yes</Button>
            <Button color="white" onClick={() => handleClose('Consent')}>
              No
            </Button>
          </Buttons>
        </ModalBox>
      </Modal>
      <Modal {...commonProps} isOpen={showAssessment}>
        <ModalBox title={title}>
          <Row size="small">
            <StaticImage
              src="./images/assessment@2x.jpg"
              alt="Bold trainers performing the strength (lower body) assessment"
              className={styles.image}
            />
          </Row>
          <Text element="p">
            Ready to take the next step in your journey to better balance? This quick assessment
            will help us understand where you’re starting from so we can celebrate every improvement
            along the way!
          </Text>
          <Buttons axis="y" size="small">
            <Button onClick={goToAssessment}>Go to assessment</Button>
            <Button color="white" onClick={() => handleClose('Assessment')}>
              Maybe another time
            </Button>
          </Buttons>
        </ModalBox>
      </Modal>
    </>
  )
}

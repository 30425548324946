import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { toast } from 'react-toastify'
import Box from 'components/Box'
import Button, { type ButtonProps } from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonWithPopover from 'components/ButtonWithPopover'
import Container from 'components/Container'
import DateTime from 'components/DateTime'
import Image from 'components/Image'
import Link from 'components/Link'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import LiveLessonSignUpButton from 'features/live-lesson/components/LiveLessonSignUpButton'
import LiveLessonSignUpButton2 from 'features/live-lesson/components/LiveLessonSignUpButton2'
import * as challengeLib from 'libs/challenge'
import type { UserChallenge } from 'libs/challenge'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as lessonLib from 'libs/lesson'
import type { Lesson, LessonStream } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'
import styles from './DashboardTodayLesson.module.scss'

type Props = {
  challenge: UserChallenge
  challengeTag: string
  lesson: Lesson
  liveLesson?: LessonStream
  loading?: boolean
} & Component

export default function DashboardLesson({
  challenge,
  challengeTag,
  className,
  lesson: lessonParameter,
  liveLesson,
  loading,
}: Props) {
  const globalContext = useGlobalContext()
  const [userChallenge, setUserChallenge] = React.useState<UserChallenge>()
  const [lesson, setLesson] = React.useState<Lesson>(lessonParameter)
  const [isChangeMoveTitle, setIsChangeMoveTitle] = React.useState<boolean>(false)
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false)
  const [refreshCount, setRefreshCount] = React.useState<number>(0)
  const isMoveChallenge = challengeTag === challengeLib.CHALLENGE_TAG.MOVE

  React.useEffect(() => {
    setUserChallenge(challenge)
  }, [challenge])

  React.useEffect(() => {
    if (!userChallenge || _.isNil(userChallenge)) return
    setRefreshCount(userChallenge.refreshCount)
  }, [userChallenge])

  React.useEffect(() => {
    async function getInfo() {
      if (isMoveChallenge && !liveLesson && lessonLib.isEducationClass(lesson.classType)) {
        setIsChangeMoveTitle(true)
      } else if (liveLesson) {
        setIsChangeMoveTitle(false)
      }
    }
    getInfo()
  }, [globalContext.analytics, isMoveChallenge, lesson, liveLesson])

  async function handleStartLesson() {
    const eventProps: any = {
      recommendedLessonId: lesson.id,
    }
    if (isMoveChallenge) {
      eventProps.moveGroup = userChallenge.lessonId ? 'algorithm' : 'program'
    } else {
      eventProps.learnGroup = !_.isNil(userChallenge.secondaryLessonIds)
        ? 'AI'
        : 'existing algorithm'
    }
    globalContext.analytics?.trackEvent(
      isMoveChallenge
        ? events.DASHBOARD_TODAYS_PLAN_MOVE_BUTTON_CLICK
        : events.DASHBOARD_TODAYS_PLAN_LEARN_BUTTON_CLICK,
      eventProps
    )

    // Move control case is always just next program class
    if (isMoveChallenge && !userChallenge.lessonId) {
      navigate(paths.PROGRAM)
    } else {
      navigate(`${paths.LESSON}${lesson.id}`, {
        state: {
          from: paths.HOME_AUTHED,
        },
      })
    }
  }

  async function handleRefreshClick() {
    if (refreshCount >= 2) return
    setIsRefreshing(true)
    let isMoveControl = null
    let nextLessonId = null

    const refreshResponse = await challengeApiRequest.refreshDailyUserChallenge(userChallenge?.id)
    if (
      refreshResponse.statusCode === 200 &&
      !_.isNil(refreshResponse.data) &&
      !_.isNil(refreshResponse.data.userChallenge)
    ) {
      const newUserChall = refreshResponse.data.userChallenge
      setUserChallenge(newUserChall)
      const refreshNumber = newUserChall.refreshCount

      if (_.isNil(newUserChall.lessonId)) {
        // Move control
        isMoveControl = 'true'
        const response = await userApiRequest.getNextProgramLessonForUser()
        if (response.statusCode === 200 && !_.isNil(response?.data) && !_.isEmpty(response?.data)) {
          setLesson(response.data[0])
          nextLessonId = response.data[0]?.id
        }
      } else {
        isMoveControl = 'false'
        const response = await lessonApiRequest.getLessonById(newUserChall.lessonId)
        if (response && response?.data) {
          setLesson(response.data)
          nextLessonId = response.data.id
        }
      }

      if (nextLessonId) {
        const eventProps: any = {
          challengeType: challengeTag,
          currentLessonId: userChallenge.lessonId, // null for Move control
          newLessonId: newUserChall.lessonId, // null for Move control
          refreshNumber,
        }
        if (isMoveChallenge) {
          eventProps.moveGroup = userChallenge.lessonId ? 'algorithm' : 'program'
        } else {
          eventProps.learnGroup = !_.isNil(userChallenge.secondaryLessonIds)
            ? 'AI'
            : 'existing algorithm'
        }
        await globalContext.analytics?.trackEvent(
          `Today's Plan: refresh button clicked`,
          eventProps
        )
        notifications.notifySuccess(
          `Your ${isMoveChallenge ? 'Move' : 'Learn'} class has been refreshed! You have ${refreshNumber === 1 ? '1' : 'no'} more ${refreshNumber === 1 ? 'refresh' : 'refreshes'} today.`
        )
        setIsRefreshing(false)
        return
      }
    }

    if (refreshResponse.data.message === 'Trying to skip last program class') {
      toast.error(
        <>
          That was the last class in your program.{' '}
          <Link to={paths.PROGRAM} decoration="underline">
            Select a new program
          </Link>
          to refresh.
        </>
      )
    } else {
      notifications.notifyError(
        `Sorry, we couldn't refresh this class. Try again later or take a different class to finish your ${isMoveChallenge ? 'Move' : 'Learn'} task today.`
      )
    }

    await globalContext.analytics?.trackEvent(`Today's Plan: refresh button failed`, {
      challengeType: challengeTag,
      currentLessonId: userChallenge.lessonId, // null for Move control
      currentRefreshNumber: userChallenge.refreshCount,
      errorMsg: refreshResponse.data.message,
      isMoveControl,
      nextLessonId,
    })
    setIsRefreshing(false)
  }

  if (_.isEmpty(lesson) && !userChallenge?.complete) {
    return null
  }

  const image = (
    <div className={styles['image-wrap']}>
      <Image src={lesson.videoThumbnail} alt="Class thumbnail" className={styles.image} />
    </div>
  )

  const label = (
    <Text color="purple" weight="bold">
      {!isMoveChallenge || isChangeMoveTitle ? 'Learn' : 'Move'}
    </Text>
  )

  const cta = 'Start class'

  const imageButtonProps: Partial<ButtonProps> = {
    'level': 'text',
    'aria-label': cta,
  }

  // todo sophia check
  if (loading) {
    return <Loader overlay={false} />
  }

  return (
    <Box
      color="white"
      className={classNames(
        'DashboardTodayLesson',
        styles.this,
        userChallenge?.complete && styles['this---completed'],
        className
      )}>
      {userChallenge?.complete ? (
        <div>{label}</div>
      ) : (
        <>
          {_.isEmpty(liveLesson) ? (
            <Button {...imageButtonProps} onClick={handleStartLesson}>
              {image}
            </Button>
          ) : (
            <LiveLessonSignUpButton2 {...imageButtonProps} data={liveLesson}>
              {image}
            </LiveLessonSignUpButton2>
          )}
          <div className={styles.body}>
            <Row size="smallx">
              <Row size="smallx">
                {label}
                <span className={styles['small--divider']}>•</span>
                Picked for you by Team Bold
              </Row>
              <Text color="black" element="div">
                <Row size="smallx">
                  <Row size="xxsmall">
                    <Text element="h3" heading size="xlarge" weight="bold" wrap="balance">
                      {lesson.videoTitle}
                    </Text>
                  </Row>
                  <Text size="large">{lesson.instructorName}</Text>
                </Row>
                <Text element="p" flush lines={2}>
                  {lesson.instructions}
                </Text>
              </Text>
            </Row>
            <footer className={styles.footer}>
              <Container align="right" flush size="xsmall" className={styles['footer--container']}>
                <Buttons equal className={styles['footer--buttons']}>
                  {_.isEmpty(liveLesson) ? (
                    <Button onClick={handleStartLesson}>Start class</Button>
                  ) : (
                    <LiveLessonSignUpButton
                      color="purple"
                      data={liveLesson}
                      full={false}
                      statusScheduledChildren={
                        <Text weight="bold">
                          Starts at <DateTime data={liveLesson?.scheduledStartTs} format="time" />
                        </Text>
                      }>
                      RSVP
                    </LiveLessonSignUpButton>
                  )}
                  {_.isEmpty(liveLesson) && (
                    <ButtonWithPopover
                      disabled={isRefreshing}
                      popover={
                        refreshCount >= 2 ? (
                          <Text
                            align="left"
                            color="white"
                            element="p"
                            flush
                            size="small"
                            className={styles.popover}>
                            Maximum daily refreshes reached. Try this class or select your own from
                            Explore or your Program to complete your Move task today.
                          </Text>
                        ) : null
                      }
                      color="white"
                      disabledFaux={refreshCount >= 2}
                      placement="top-end"
                      onClick={handleRefreshClick}>
                      Refresh
                    </ButtonWithPopover>
                  )}
                </Buttons>
              </Container>
            </footer>
          </div>
        </>
      )}
    </Box>
  )
}

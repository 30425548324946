import { Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import React from 'react'
import Button from 'components/Button'
import Divider from 'components/Divider'
import Field2 from 'components/Field2'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as notifications from 'libs/notifications'

type Props = {
  isOpen: boolean
  onClose: () => void
  onUpdate: () => void
}

export default function AdminRerunStreamCreateModal({
  isOpen,
  onClose,
  onUpdate: handleUpdate,
  ...props
}: Props) {
  const initialValues = {}
  const [lessonRegimens, setLessonRegimens] = React.useState([])

  async function getRegimenList() {
    const response = await adminApiRequest.getLessonRegimens()
    if (response && response.statusCode === 200) {
      setLessonRegimens(response.data)
    }
  }

  React.useEffect(() => {
    getRegimenList()
  }, [])

  async function handleSubmit(values) {
    const rerunTime = DateTime.fromISO(values.rerunTimePT)
    const timestamp = DateTime.fromFormat(values.rerunDate, 'MM/dd/yyyy')

    const createResult = await adminApiRequest.createOneoffRerunStream(
      timestamp
        .set({
          hour: rerunTime.hour,
          minute: rerunTime.minute,
        })
        .toJSDate(),
      values.lessonRegimenId
    )
    if (createResult.statusCode === 200) {
      notifications.notifySuccess('Successfully created new rerun!')
      if (onClose) onClose()
    } else if (createResult.statusCode === 404) {
      notifications.notifyError('No rerun available for selected series!')
    } else {
      notifications.notifyError('Ruh roh something went wrong')
    }
    if (handleUpdate) handleUpdate()
  }

  const regimenOptions = lessonRegimens.map((regimen) => ({
    label: `${regimen.title} - ${regimen.instructorName}`,
    value: regimen.id,
  }))

  return (
    <Modal {...props} isOpen={isOpen} size="xxlarge" onRequestClose={onClose}>
      <ModalBox>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(formikProps) => (
            <Form>
              <Field2
                label="What series is the rerun for?"
                name="lessonRegimenId"
                type="select"
                options={regimenOptions}
              />
              <Field2 label="Class date (MM/DD/YYYY)" name="rerunDate" />
              <Field2
                label="Class time (PT) (eg 08:00 for 8AM PT, 15:00 for 3PM PT)"
                name="rerunTimePT"
              />
              <Divider thin />
              <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                Create Rerun class
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBox>
    </Modal>
  )
}

import _ from 'lodash'
import qs from 'qs'
import { SWROptions, useSWRGet } from 'libs/swr'
import { validateNoUndefinedFields, validateType } from './validations'

export function useLatestChallengeForCollectionId(collectionId: string, options: SWROptions) {
  let path = null
  if (validateType(collectionId, 'uuid')) path = `challengeForCollection/${collectionId}`
  return useSWRGet(path, options)
}

type ChallengeSearchParameters = {
  isChallengeActive?: boolean
  tag?: string
  type?: string
}

export type UserChallengeSearchParameters = {
  isChallengeActive?: boolean
  tag?: string
  type?: string
  challenge_id?: string
  status?: string
}

export const USER_CHALLENGE_KEY = `challenges/user?${qs.stringify({
  isChallengeActive: true,
  status: 'active',
  type: 'daily',
})}`

export function useUserChallenges(parameters: UserChallengeSearchParameters, options: SWROptions) {
  const hasNoUndefinedFields = validateNoUndefinedFields(parameters)
  const path = 'challenges/user'
  return useSWRGet(
    hasNoUndefinedFields
      ? `${path}?${qs.stringify({
          ...parameters,
        })}`
      : path,
    options
  )
}

export function useActiveChallenges(parameters: ChallengeSearchParameters, options: SWROptions) {
  const path = 'challenges'
  return useSWRGet(
    !_.isEmpty(parameters)
      ? `${path}?${qs.stringify({
          ...parameters,
        })}`
      : path,
    options
  )
}

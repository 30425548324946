import { differenceInCalendarDays } from 'date-fns'
import { navigate } from 'gatsby'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Copy from 'components/Copy'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as cookie from 'libs/cookie'
import * as user from 'libs/user'

export default function EnterpriseEligibilityChangeModal() {
  const globalContext = useGlobalContext()
  const [isModalOpen, setIsModalOpen] = React.useState(
    !cookie.getCookie(storage.ENTERPRISE_ELIGIBILITY_MODAL)
  )

  if (!user.isEnterpriseTerminatedUser(globalContext.user)) return null

  const days = globalContext.user?.subscription?.trial_end
    ? differenceInCalendarDays(
        new Date(globalContext.user.subscription.trial_end * 1000),
        new Date()
      )
    : undefined

  function handleCloseModal() {
    setIsModalOpen(false)
    cookie.createCookie(storage.ENTERPRISE_ELIGIBILITY_MODAL, storage.MODAL_SHOWN)
  }

  function handleEnterpriseEligibleButtonClick() {
    handleCloseModal()
    globalContext.analytics?.trackEvent(events.ENTERPRISE_TERMINATED_MODAL_ELIGIBLE_CLICK)
    navigate(paths.ENTERPRISE_ELIGIBLE)
  }

  function handleSubscriptionOptionsButtonClick() {
    handleCloseModal()
    globalContext.analytics?.trackEvent(events.ENTERPRISE_TERMINATED_MODAL_SUBSCRIPTIONS_CLICK)
    navigate(paths.SETTINGS_SUBSCRIPTION_CHANGE)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      className="EnterpriseEligibilityChangeModal"
      onRequestClose={handleCloseModal}>
      <ModalBox
        title={`${globalContext.user?.firstName ? `${globalContext.user.firstName}, your` : `Your`} free access to Bold has ended`}>
        <Row size="small">
          <Copy>
            <Text element="p">Here’s why your access changed:</Text>
            <Text element="p">
              You either changed health plans OR your health plan no longer offers Bold.
            </Text>
            <Text element="p">
              You still have {!_.isNil(days) ? `${pluralize('day', days, true)} of` : ''} FREE
              access to Bold. After this, your access will be limited or you can choose to pay.
            </Text>
          </Copy>
        </Row>
        <Buttons align="center" equal>
          <Button onClick={handleEnterpriseEligibleButtonClick}>Check my eligibility again</Button>
          <Button color="white" onClick={handleSubscriptionOptionsButtonClick}>
            Subscription options
          </Button>
        </Buttons>
      </ModalBox>
    </Modal>
  )
}

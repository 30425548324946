import { Form, Formik } from 'formik'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import Button from 'components/Button'
import Divider from 'components/Divider'
import Field2 from 'components/Field2'
import FieldRadioButtons from 'components/FieldRadioButtons'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as notifications from 'libs/notifications'

type Props = {
  isOpen: boolean
  onClose: () => void
  onUpdate: () => void
}

export default function AdminLiveStreamCreateModal({
  isOpen,
  onClose,
  onUpdate: handleUpdate,
  ...props
}: Props) {
  const initialValues = {
    classType: '',
    requiredItems: '',
    duration: 45,
  }
  const [lessonRegimens, setLessonRegimens] = React.useState([])

  async function getRegimenList() {
    const response = await adminApiRequest.getLessonRegimens()
    if (response && response.statusCode === 200) {
      setLessonRegimens(response.data)
    }
  }

  React.useEffect(() => {
    getRegimenList()
  }, [])

  async function handleSubmit(values) {
    const classType =
      !_.isNil(values.classType) || values.classType.length === 0
        ? values.classType.split(',').map((type) => _.capitalize(type.trim()))
        : null
    const requiredItems = !_.isNil(values.requiredItems)
      ? values.requiredItems
          .split(',')
          .map((item) => _.capitalize(item.trim()))
          .join(', ')
      : ''
    const liveTime = DateTime.fromISO(values.liveTimePT)
    const timestamp = DateTime.fromFormat(values.liveDate, 'MM/dd/yyyy')
    const dataToSave = {
      videoTitle: values.videoTitle,
      videoLink: null,
      instructions: values.instructions,
      duration: values.duration,
      instructorName: values.instructorName,
      videoThumbnail: values.videoThumbnail,
      type: values.type,
      classType,
      requiredItems,
    }
    const createResult = await adminApiRequest.createOneoffLiveStream(
      dataToSave,
      timestamp
        .set({
          hour: liveTime.hour,
          minute: liveTime.minute,
        })
        .toJSDate(),
      values.lessonRegimenId,
      values.instructorEmail
    )
    if (createResult.statusCode === 200) {
      notifications.notifySuccess('Successfully created new live class!')
      if (onClose) onClose()
    } else {
      notifications.notifyError('Ruh roh something went wrong')
    }
    if (handleUpdate) handleUpdate()
  }

  const positionOptions = [
    {
      text: 'Seated',
      value: 'sitting',
    },
    {
      text: 'Standing',
      value: 'standing',
    },
    {
      text: 'Floor',
      value: 'floor',
    },
    {
      text: 'Seated/Standing',
      value: 'sitting/standing',
    },
    {
      text: 'Seated/Floor',
      value: 'sitting/floor',
    },
    {
      text: 'Standing/Floor',
      value: 'standing/floor',
    },
    {
      text: 'Seated/Standing/Floor',
      value: 'sitting/standing/floor',
    },
  ]

  const regimenOptions = lessonRegimens.map((regimen) => ({
    label: `${regimen.title} - ${regimen.instructorName}`,
    value: regimen.id,
  }))

  return (
    <Modal {...props} isOpen={isOpen} size="xxlarge" onRequestClose={onClose}>
      <ModalBox>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(formikProps) => (
            <Form>
              <Field2
                label="Title"
                // labelNote="(comma separated, will be ignored if email doesn’t match a user)"
                name="videoTitle"
              />
              <Field2 label="Description" name="instructions" />
              <Field2 label="Instructor name" name="instructorName" />
              <Field2 label="Instructor email" name="instructorEmail" />
              <Field2 label="Video thumbnail URL" name="videoThumbnail" />
              <FieldRadioButtons
                size="small"
                key="type"
                label="Position"
                name="type"
                options={positionOptions}
              />
              <Field2 label="Required requiredItems (comma separated)" name="requiredItems" />
              <Field2
                label="How long are these classes usually? (enter a number in minutes)"
                name="duration"
              />
              <Field2 label="Class types (comma separated)" name="classType" />
              <Field2 label="Class date (MM/DD/YYYY)" name="liveDate" />
              <Field2
                label="Class time (PT) (eg 08:00 for 8AM PT, 15:00 for 3PM PT)"
                name="liveTimePT"
              />
              <Field2
                label="Connect to series (recommended)"
                name="lessonRegimenId"
                type="select"
                options={regimenOptions}
              />
              <Divider thin />
              <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                Create Live class
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBox>
    </Modal>
  )
}
